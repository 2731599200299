import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchPortalPage } from '../../actions'
import SpecialsModal from '../../components/SpecialsModal'
import BannerSliderFour from '../../components/widgets/BannerSliderFour'
import BannerSliderThree from '../../components/widgets/BannerSliderThree'
import BestDeal from '../../components/widgets/BestDeal'
import FeaturesV2 from '../../components/widgets/FeaturesV2'
import LinkColumn from '../../components/widgets/LinkColumn'
import InlineContentLoader from '../global/loaders/InlineContentLoader'
import FaqBlock from './FaqBlock'
import GenericBlock from './GenericBlock'

const HomePage = ({
  currentLocation,
  location,
  portal,
  match,
  userCredits,
  fetchPortalPage,
  page,
  customerGroup,
  loading
}) => {
  const [coopPercent, setCoopPercent] = useState(0)

  useEffect(() => {
    const locationId = currentLocation && currentLocation.id

    if (location.state && location.state.page_id) {
      fetchPortalPage(location.state.page_id, locationId)
    } else {
      portal.pages &&
        portal.pages.map(page => {
          if (page.active_url === match.path && page.location_id === currentLocation.id) {
            fetchPortalPage(page.id, locationId)
          }
        })
    }

    let coopPercentage = 0
    if (userCredits && userCredits.coop_credit && userCredits.coop_credit.percentage) {
      coopPercentage = userCredits.coop_credit.percentage / 100
    } else if (portal.coop_enabled && portal.default_location && portal.default_location.coop_enable) {
      coopPercentage = portal.default_location.coop_overwrite
        ? portal.default_location.coop_percentage_100 / 100
        : portal.coop_percentage_100 / 100
    }
    setCoopPercent(coopPercentage)
  }, [])

  if (!page) return null
  if (loading) return <InlineContentLoader />

  const currDate = new Date()
  const currTimestamp = currDate.getTime()

  return (
    <div>
      {page.page_content_blocks &&
        page.page_content_blocks
          .sort((a, b) => a.order - b.order)
          .map((block, i) => {
            const { content_type } = block

            const wrapContent = (content, key) => (
              <div className="iron-home-v2-wrap" key={key}>
                <div className={`${content.wrapperClassName} py-30`}>
                  <div className="container">{content.child}</div>
                </div>
              </div>
            )

            if (content_type === 'Slider') {
              return wrapContent(
                {
                  wrapperClassName: 'iron-banner-wrapper',
                  child: <BannerSliderThree blockContent={block.child_content} />
                },
                i
              )
            }
            if (content_type === 'LinkColumn') {
              return wrapContent(
                {
                  wrapperClassName: 'iron-cta-wrapper',
                  child: <LinkColumn linkColItems={block.child_content.items} />
                },
                i
              )
            }
            if (content_type === 'Feature') {
              return wrapContent(
                {
                  wrapperClassName: 'iron-feature-wrapper',
                  child: <FeaturesV2 siteFeatures={block.child_content.items} />
                },
                i
              )
            }
            if (
              content_type === 'Deal' &&
              customerGroup.id &&
              !customerGroup.hide_products &&
              block.child_content.product &&
              !customerGroup.hidden_products_list.find(p => p.id == block.child_content.product.id) &&
              (new Date(block.child_content.product.sale_price_to_date_and_time).getTime() - currTimestamp) / 1000 > 1
            ) {
              return wrapContent(
                {
                  wrapperClassName: 'iron-banner-wrapper',
                  child: <BestDeal block={block} portal={portal} />
                },
                i
              )
            }
            if (content_type === 'ProductSlider' && customerGroup.id && !customerGroup.hide_products) {
              const filtered_product_slider = block.child_content.items.filter(
                item => item && !customerGroup.hidden_products_list.find(p => p.id == item.product.id)
              )
              let filtered_categories = []
              let fully_filtered_categories = []
              if (!block.child_content.hide_category_selector) {
                filtered_categories = block.child_content.categories.filter(
                  cat =>
                    cat && !customerGroup.hidden_categories_list.find(c => c.id == cat.id) && cat.portal_id == portal.id
                )
                filtered_product_slider.forEach(product => {
                  product.categories.forEach(category => {
                    if (filtered_categories.find(c => c.id == category.id)) {
                      fully_filtered_categories.push(category)
                    }
                  })
                })
                fully_filtered_categories = _.uniqBy(fully_filtered_categories, 'id')
              }
              if (filtered_product_slider.length !== 0) {
                return wrapContent(
                  {
                    wrapperClassName: 'iron-banner-wrapper',
                    child: (
                      <BannerSliderFour
                        title={block.title}
                        sliderData={filtered_product_slider}
                        portal={portal}
                        categories={fully_filtered_categories.sort((a, b) => a.name.localeCompare(b.name))}
                        hide_category_selector={block.child_content.hide_category_selector}
                        coopPercent={coopPercent}
                      />
                    )
                  },
                  i
                )
              }
            }
            if (content_type === 'Generic') {
              return (
                <div className="iron-home-v2-wrap py-30" key={i}>
                  <GenericBlock content={block} />
                </div>
              )
            }
            if (content_type === 'FAQ') {
              return (
                <div className="iron-home-v2-wrap py-30" key={i}>
                  <div className="container">
                    <FaqBlock content={block} />
                  </div>
                </div>
              )
            }

            return null
          })}

      {portal.portal_has_promo && <SpecialsModal promoBg={portal.portal_promo_image.medium.url} />}
    </div>
  )
}

const WrapperComponent = ({ children, className, container }) => (
  <div className={`iron-home-v2-wrap ${className}`}>
    {container ? <div className="container">{children}</div> : children}
  </div>
)

const mapStateToProps = state => ({
  portal: state.portal,
  customerGroup: state.customerGroup,
  currentLocation: state.currentLocation
})

export default connect(mapStateToProps, { fetchPortalPage })(HomePage)
