import { Link } from 'react-router-dom'
import { Row, Col } from 'reactstrap'

export default function LinkColumn({ linkColItems }) {
  const getColumnSize = length => {
    if (length === 1) return { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }
    if (length === 2) return { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }
    return { xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }
  }

  const columnSize = getColumnSize(linkColItems.length)

  const isExternalLink = url => url.startsWith('http://') || url.startsWith('https://')

  return (
    <div>
      <Row className="iron-call-to-action-wrap">
        {linkColItems
          .sort((a, b) => a.order - b.order)
          .map((linkColItem, index) => (
            <Col key={index} {...columnSize} className="iron-col">
              <div className="iron-cta-item custom-cta">
                {isExternalLink(linkColItem.link) ? (
                  <a href={linkColItem.link} target="_blank" rel="noopener noreferrer">
                    <img src={linkColItem.image.url} alt="call-to-action" />
                    <h4>{linkColItem.title}</h4>
                    <p>{linkColItem.sub_title}</p>
                  </a>
                ) : (
                  <Link to={linkColItem.link}>
                    <img src={linkColItem.image.url} alt="call-to-action" />
                    <h4>{linkColItem.title}</h4>
                    <p>{linkColItem.sub_title}</p>
                  </Link>
                )}
              </div>
            </Col>
          ))}
      </Row>
    </div>
  )
}
